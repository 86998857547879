import { FlatCard } from 'components/FlatCard/FlatCard';
import React, { useState, useMemo } from 'react';
import { SingleFlatType } from 'types/CommonTypes';
import {
    Button,
    Container,
    FlatCardWrapper,
    FlatsListMobileWrapper,
    LoadMoreStyled,
    SectionTitle,
} from './FloorMobile.styled';

export interface FlatsHeroMobileProps {
    data: any;
    investmentSlug: string;
}

export const FloorMobile = ({ data, investmentSlug }: FlatsHeroMobileProps) => {
    const buildingFloor = data.floorData.floorTax;
    const flatsData = useMemo(() => {
        return data?.floorData?.searchMap?.map((item: any) => ({
            ...item.flat.investmentFlat,
            flatStatus: item.flat.investmentFlat.flatStatus?.name,
            investmentBuilding:
                item.flat.investmentFlat.investmentBuilding.name,
            buildingFloor: buildingFloor,
        }));
    }, [data.floorData.searchMap]);

    const [numberOfRows, setNumberOfRows] = useState(5);

    const loadMore = () => {
        setNumberOfRows((prevState) => prevState + 5);
    };

    const cutFlats = flatsData.slice(0, numberOfRows);

    return (
        <Container>
            <SectionTitle>Lista mieszkań</SectionTitle>
            <Button to={'/garaze'}>Zobacz garaże i komórki lokatorskie</Button>
            <FlatsListMobileWrapper>
                {cutFlats?.map((flat: SingleFlatType, index: number) => {
                    return (
                        <FlatCardWrapper>
                            <FlatCard
                                flatInfo={flat}
                                key={index}
                                investmentSlug={investmentSlug}
                            />
                        </FlatCardWrapper>
                    );
                })}
                {flatsData.length > numberOfRows && (
                    <LoadMoreStyled onClick={loadMore}>
                        Załaduj więcej
                    </LoadMoreStyled>
                )}
            </FlatsListMobileWrapper>
        </Container>
    );
};
